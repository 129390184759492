a {
    color: #e0f2f1;
    text-decoration: none;
}

a:hover {
    color: #ffffff;
    /* Brighter white on hover */
}

a:visited {
    color: #e0f2f1;
    /* Ensures visited links have the same color */
}

.icon-teal {
    color: #00796b;
}

/* Remove the overlapping lines */
.carousel-item {
    overflow: hidden;
    gap: 10px;
    /* Ensures content does not overflow the boundaries */
}

/* Move the navigation arrows to the extreme left and right */
.carousel-control-prev,
.carousel-control-next {
    width: 5%;
    /* Adjust the button's width */
}

.carousel-control-prev {
    left: 1%;
    /* Move to the extreme left */
}

.carousel-control-next {
    right: 1%;
    /* Move to the extreme right */
}

/* Customize the button look (optional) */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    
    /* Match the theme color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
}